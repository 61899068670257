export const calculoTipos = {
	prelacion: "PRELACION",
	manual: "MANUAL"
};

export const aplicacionEstadoTipos = {
	inicial: "NO-APLICADO",
	prelacion: "PRELACION-APLICADO",
	manual: "MANUAL-APLICADO"
};

export const condonarTipos = {
    No: 0,
    Recargos: 1,
    Restante: 2,
    Cantidad: 3
};

export const condonarModes = {
	No: 0,
	Condonar: 1,
	SoloCondonar: 2
};