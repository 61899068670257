import React from "react";
import Request from "../../../core/httpClient";
import SuperCore from "../../../components/core/SuperCore";
import SuperModal from "../../../components/coreModal/SuperModal";
import DocTable from "./docsTable";
import PaymentHistory from "./paymentDocumentHistory";
import NumberFormat from "react-number-format";
import AplicarPagoModal from "./AplicarPagoModal";

const request = new Request();

class DocsTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			screenHistory: [],
			id_docActivo: [],
			id_doc: [],
			detail: [],
			fracName: this.props.fracName,
			subfraccionamientos: this.props.subfraccionamientos,
			vivienda_id: 0,
			unidad: "",
			userName: this.props.userName,
			userId: this.props.userId,
			usoPrelacion: true,
			loadingDocumentsVivienda: false,
			subLabel: "",
		};
	};

	async componentDidMount() {
		await this.getSubLabel();
		await this.updateScreensHistory();
		await this.getParameters();
	};

	async getSubLabel() {

		const subLabel = await window.localStorage.getItem("subLabel");

		if (subLabel) {
			this.setState({ subLabel });
		};

	};

	async componentDidUpdate(prevProps, prevState) {

		if (prevProps.docs !== this.props.docs) {
			await this.setState({ rules: this.props.docs });
		};

		if (this.props.subfraccionamientos !== prevProps.subfraccionamientos) {
			await this.setState({ subfraccionamientos: this.props.subfraccionamientos });
		};

		if (this.props.fracName !== prevProps.fracName) {
			await this.setState({ fracName: this.props.fracName });
		};

	};

	async getParameters() {

		let data = {
			id_fraccionamiento: parseInt(localStorage.getItem("frac")) || 0,
		};

		const response = await request.post("/facturacion/config", data);

		if (!response.error) {

			let campos = JSON.parse(response.config[0].Campos);

			campos.forEach((campo) => {

				if (campo.id_campo === 16) {
					this.setState({ usoPrelacion: campo.Valor[0].valor.toUpperCase() === "SI" ? true : false });
				};

			});
		};
	};

	async getHistoryDocuments(doc) {

		this.superOpenModal("paymentHistory");

		await this.setState({ Id_Vivienda: doc.Id_Vivienda, unidad: doc.Unidad });

		await this.updateScreensHistory();
	};

	async openRow(id) {

		if (id === this.state.id_docActivo) {
			await this.setState({ id_docActivo: 0 });
		} else {
			this.setState({ id_docActivo: id, id_vivienda_row: id });
			this.getDocumentsDetail(id);
		};
	};

	async getDocumentsDetail(id) {

		this.setState({ detail: [], loadingDocumentsVivienda: true });

		let data = {
			id_vivienda: id,
		};

		const response = await request.post("/documentos/get/documents/byHouse", data);

		if (!response.empty && !response.error) {
			await this.setState({ detail: response.documentsInfo });
		} else {
			await this.setState({ detail: [] });
		}

		this.setState({ loadingDocumentsVivienda: false });
	};

	///// estado de cuenta
	async updateScreensHistory() {
		await this.setState({
			screenHistory: [
				<PaymentHistory
					subfraccionamientos={this.state.subfraccionamientos}
					Id_Vivienda={this.state.Id_Vivienda}
					unidad={this.state.unidad}
					fracName={this.state.fracName}
					subLabel={this.state.subLabel}
				/>,
			],
		});
	};

	openAplicarPagoModal(doc) {

		this.setState({
			vivienda_id: doc.Id_Vivienda,
			unidad: doc.Unidad
		})
	}

	closeAplicarPagoModal() {
		this.setState({ vivienda_id: 0 });
	}

	onAplicarPagoSuccessful() {
		this.closeAplicarPagoModal();
		this.props.getDataDocuments();
	}

	async closeHistory() {
		this.setState({
			Id_Vivienda: 0,
			unidad: "",
		});
		await this.updateScreensHistory();
	};

	render() {
		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="row">
					<table className="full">
						<thead id={this.props.id ? this.props.id : ''} className="sticky-th">
							<tr className="text-left">
								<th className="text-start ">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "Unidad" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "Unidad")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "Unidad")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "Unidad")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-start ">
									<div className="row">
										Propietario &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === "Propietario" ? (
												this.props.filterColumn.filt === "asc" ? (
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, "Propietario")}></i>
												) : (
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, "Propietario")}></i>
												)
											) : (
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, "Propietario")}></i>
											)}
										</div>
									</div>
								</th>
								<th className="text-center">Cargos</th>
								<th className="text-center">Pagos</th>
								<th className="text-center">Saldo</th>
								<th className="text-center">Saldo a favor</th>
								<th className="text-center th-options">Detalle</th>
								<th className="text-center th-options"></th>
								<th className="text-center th-options"></th>
							</tr>
						</thead>
						<tbody>
							{this.props.docs.length === 0 ? (
								<tr>
									<td colSpan="9" className="text-center">
										Sin datos que mostrar.
									</td>
								</tr>
							) : (
								this.props.docs
									.filter(
										(dc) =>
											dc.Unidad.toLowerCase().includes(this.props.filter) ||
											dc.Propietario.toLowerCase().includes(this.props.filter)
									)
									.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength)
									.map((doc, key) => (
										<React.Fragment key={key}>
											<tr className={this.state.id_vivienda_row === doc.Id_Vivienda ? "row-active text-left" : ""}>
												<td className="text-start">{doc.Unidad}</td>
												<td className="text-start">{doc.Propietario}</td>
												<td className="text-right">
													<NumberFormat
														value={doc.Cargos}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</td>
												<td className="text-right">
													<NumberFormat
														value={doc.Pagos}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</td>
												<td className="text-right">
													<NumberFormat
														value={doc.Saldo}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</td>
												<td className="text-right">
													{doc.Saf > 0 && (
														<NumberFormat
															value={doc.Saf}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$"}
															decimalScale={2}
															fixedDecimalScale={true}
														/>
													)}
												</td>

												<td>
													<button
														className="btn-full btn-mini btn-secondary color-white"
														type="button "
														onClick={() => {
															this.openRow(doc.Id_Vivienda);
														}}
													>
														<i className="font-small fas fa-info-circle" />
													</button>
												</td>
												<td className="text-right">
													<button
														title="Estado de Cuenta"
														className="btn btn-primary btn-red-table color-white"
														onClick={(event) => {
															this.getHistoryDocuments(doc);
														}}
													>
														Edo. Cta.
													</button>
												</td>
												<td className="text-right width-small ">
													<button
														className="btn btn-primary btn-red-table color-white"
														onClick={(event) => 
															this.openAplicarPagoModal(doc)
														}
													>
														Pagar
													</button>
												</td>
											</tr>
											{this.state.id_docActivo === doc.Id_Vivienda ? (
												this.state.loadingDocumentsVivienda ? (
													<tr>
														<td className="text-center" colSpan="9">
															<i className="fas fa-spinner fa-spin"></i>
														</td>
													</tr>
												) : (
													<tr className="acordeon-row">
														<td className="acordeon" colSpan="9">
															<div className="row full">
																<DocTable
																	key={key}
																	docs={this.state.detail}
																	escritura={this.props.escritura}
																	reload={() => this.props.reload()}
																	status={this.props.filterStatus}
																	year={this.props.filterYear}
																/>
															</div>
														</td>
													</tr>
												)
											) : null}
										</React.Fragment>
									))
							)}
						</tbody>
					</table>
				</div>
				<AplicarPagoModal 
					viviendaInfo={{ unidad: this.state.unidad, id_vivienda: this.state.vivienda_id }}
					userInfo={{ userName: this.state.userName, userId: this.state.userId }}
					enabledPrelacion={this.state.usoPrelacion}
					metodosPago={this.props.metodosPago}
					onCloseModal={this.closeAplicarPagoModal.bind(this)}
					onSuccessfulSubmit={this.onAplicarPagoSuccessful.bind(this)}
				/>
				<SuperModal
					id={"paymentHistory"}
					size={"huge"}
					title={"Histórico estados de cuenta"}
					screens={this.state.screenHistory}
					onSubmit={() => { }}
					showSubmit={false}
					triggerCloseModal={this.closeHistory.bind(this)}
				/>
			</div>
		);
	};
};

export default DocsTable;