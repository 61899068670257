import React from 'react';

export default function CheckBox(props) {

    const { title, checked, disabled, style, onChecked } = props;

    return (
        <label
            className="container-check"
            style={{ display: "flex", alignItems: "center", ...style}}
        >
            <input
                type="checkbox"
                name="checkbox"
                checked={checked}
                disabled={disabled}
                className="check-option"
                onChange={onChecked}
            />
            <span className="checkmark"></span>
            {title && <p style={{ alignSelf: "center"}}>{title}</p>}
        </label>
    );
}

