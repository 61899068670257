// React and third-parties
import React, { useState, useRef, useCallback } from 'react';
import cogoToast from 'cogo-toast';

// Types
import { condonarTipos, aplicacionEstadoTipos } from '../helpers/pagoTypes';

// Helpers
import { calcularRecargosYDescuentos, calcularPagoPrelacion2, SetDocPagoData } from '../helpers/pagoHelpers';

// Requests
import { getDocumentosPendientesDeCobro } from '../helpers/requests';

const toastConfig = { position: "bottom-right", hideAfter: 5 };

export const useDocumentosDeCobro = (id_vivienda, pagoInfo, aplicacionEstado) => {

    const currentIdVivienda = useRef(0); currentIdVivienda.current = id_vivienda;

    const [loadingDocumentos, setLoadingDocumentos] = useState(false);
    const [documentosDeCobro, setDocumentosDeCobro] = useState([]);
    const [saldoAFavor, setSaldoAFavor] = useState(0);

    const { importe } = pagoInfo;

    const aplicadosTotales = documentosDeCobro.reduce((totales, documento) => {

        const { Importe, Descuento, Recargos, Total,
            Pagado, Saldo, Aplicado, Condonacion, Condonar } = documento;

        if (Aplicado !== 0) {
            totales.Importes += Importe;
            totales.Descuentos += Descuento;
            totales.Recargos += Recargos;
            totales.Totales += Total;
            totales.Pagados += Pagado;
            totales.Saldos += Saldo;
            totales.Aplicados += Aplicado;
        }

        if (Condonar) {
            totales.Condonados += Condonacion;
        }

        return totales;

    }, { 
        Importes: 0, Descuentos: 0, Recargos: 0, 
        Totales: 0, Pagados: 0, Saldos: 0,
        Aplicados: 0, Condonados: 0 
    });

    const saldoTotal = documentosDeCobro.reduce((suma, doc) => suma + doc.Saldo, 0);

    const resetCalculos = useCallback(() => setDocumentosDeCobro(prevDocumentos => 

        prevDocumentos.map(documento => new SetDocPagoData(documento).resetPagoData().documento)
    ), []);

	const loadDocumentosDeCobro = useCallback(async (search_id_vivienda) => {

		setLoadingDocumentos(true);

		const { documentosPendientes, saldoAFavor, error } = await getDocumentosPendientesDeCobro(search_id_vivienda);

        if(search_id_vivienda !== currentIdVivienda.current) return;

		if(error) {
			cogoToast.error(error, toastConfig);
		} else {
            const documentosCalculados = calcularRecargosYDescuentos(documentosPendientes, new Date());
			setDocumentosDeCobro(documentosCalculados);
			setSaldoAFavor(saldoAFavor);
		}

		setLoadingDocumentos(false);

	}, []);

    const checkDocAplicar = useCallback((index, checked) => setDocumentosDeCobro(prevDocumentos => {

        if(!prevDocumentos[index].checked && aplicadosTotales.Aplicados === importe) {

            cogoToast.warn("Importe seleccionado alcanzado", toastConfig);
            return prevDocumentos;
        }

        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]).setDocAplicacion(checked, 0);
        const { Condonar } = documentosCopy[index];

        if (!checked) {

            if (Condonar === condonarTipos.Recargos)
                newDoc.resetDocCondonacion().resetDocTotales();

            if (Condonar === condonarTipos.Restante)
                newDoc.setDocCondonacion(Condonar, newDoc.saldoPreCondonar)
                    .setDocTotales(newDoc.totalPreCondonar - newDoc.saldoPreCondonar, 0);
        }

        documentosCopy[index] = newDoc.documento;

        return documentosCopy;
        
    }), [aplicadosTotales, importe]);

    const setDocAplicarCantidad = useCallback((index, aplicar) => setDocumentosDeCobro(prevDocumentos => {

        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]);
        const { Pagado, checked } = newDoc.documento;

        const { applyCantidad, applyCondonar, reachedImporte } = newDoc.getApplyInfo(aplicar, aplicadosTotales.Aplicados, importe);

        if (reachedImporte) setUnappliedAsUnchecked(documentosCopy);

        documentosCopy[index] = applyCondonar !== condonarTipos.No ?
            newDoc
                .setDocAplicacion(checked, applyCantidad)
                .setDocTotales(applyCantidad + Pagado, applyCantidad)
                .setDocCondonacion(applyCondonar, newDoc.saldoPreCondonar - applyCantidad).documento
            :
            newDoc
                .setDocAplicacion(checked, applyCantidad)
                .resetDocTotales()
                .setDocCondonacion(applyCondonar, 0).documento;

        return documentosCopy;

    }), [aplicadosTotales, importe]);

    const setDocCondonarCantidad = useCallback((index, cantidad) => setDocumentosDeCobro(prevDocumentos => {

        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]);
        const { Pagado, Condonar } = newDoc.documento;

        const { applyCondonar, applyCantidad } = newDoc.getApplyCondonacionInfo(cantidad, saldoTotal, pagoInfo.importe);

        documentosCopy[index] = newDoc
            .setDocAplicacion(applyCantidad !== 0, applyCantidad)
            .setDocTotales(applyCantidad + Pagado, applyCantidad)
            .setDocCondonacion(Condonar, applyCondonar).documento;

        const { documentosOrdenados } = calcularPagoPrelacion2(documentosCopy, pagoInfo);

        return documentosOrdenados;

    }), [saldoTotal, pagoInfo]);
    

    const selectDocCondonar = useCallback((index, condonar) => setDocumentosDeCobro(prevDocumentos => {
        
        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]);

        const { Importe, Recargos, Pagado, Aplicado } = newDoc.documento;

        if (condonar === condonarTipos.No) {
            documentosCopy[index] = newDoc
                .resetDocCondonacion()
                .resetDocTotales().documento;
        }

        if (condonar === condonarTipos.Recargos) {

            if (aplicacionEstado === aplicacionEstadoTipos.manual)
                newDoc.setDocAplicacion(true, Importe - Pagado);

            documentosCopy[index] = newDoc
                .setDocCondonacion(condonar, Recargos)
                .setDocTotales(Importe, Importe - Pagado).documento;
        }

        if (condonar === condonarTipos.Restante) {
            documentosCopy[index] = newDoc
                .setDocCondonacion(condonar, newDoc.saldoPreCondonar - Aplicado)
                .setDocTotales(Aplicado + Pagado, Aplicado).documento;
        }

        if (condonar === condonarTipos.Cantidad) {

            documentosCopy[index] = newDoc
                .setDocCondonacion(condonar, 0).documento;
        }

        return aplicacionEstado === aplicacionEstadoTipos.prelacion ?
            calcularPagoPrelacion2(documentosCopy, pagoInfo).documentosOrdenados
            : documentosCopy;

    }), [pagoInfo, aplicacionEstado]);

    return { 
        loadingDocumentos,
        documentosDeCobro,
        saldoAFavor,
        aplicadosTotales,
        saldoTotal,
        documentosActions: {
            loadDocumentosDeCobro,
            setDocumentosDeCobro, setSaldoAFavor,
            checkDocAplicar, setDocAplicarCantidad, setDocCondonarCantidad,
            selectDocCondonar,
            resetCalculos
        }
    };
};

const setUnappliedAsUnchecked = (documentos) => {
    for (let index = 0; index < documentos.length; index++) {
        const { Aplicado, checked } = documentos[index];
        
        if(checked && Aplicado === 0)
            documentos[index] = new SetDocPagoData(documentos[index]).resetDocAplicacion().documento;
    }
};
