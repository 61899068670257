import React, { useState, useRef, useReducer, useCallback } from 'react';

// Requests
import { getSubfraccionamientos, getViviendas, getProductos, getInformes } from '../requests';

// Hooks
import { setSelectFormat, getSelected } from '../../../hooks/useMuliSelectFormat';
import moment from 'moment';

export const desgloseTipos = {
    Detallado: 0,
    Concentrado: 1
};

export const pagoEstadosTipos = [
    "Todos",
    "Pagados",
    "Pendientes"
];

export const vaciosTipos = [
    "Ocultar",
    "Mostrar"
];

const setDefaultSelectFormat = (format, checked = true, disabled = false) => {
    const { id, option } = format;

    return setSelectFormat(id, option, checked, disabled);
};

const setViviendasSelectFormat = (format, checked = true, disabled = false) => {
    const { id, id_sub, option } = format;

    return { ...setSelectFormat(id, option, checked, disabled), id_sub }
};

const infoListExtractors = {
    subfraccionamientos: { 
        getFormat: ({ id_subfraccionamiento: id, nombre }) => setSelectFormat(id, nombre),
        getIdsKey: "subsIds"
    },
    viviendas: {
        getFormat: ({ id_vivienda: id, id_subfraccionamiento: id_sub, numero_registro }) => {
            return { ...setSelectFormat(id, numero_registro), id_sub }},
        getIdsKey: "vivsIds"
    },
    productos: {
        getFormat: ({ Id_Producto: id, Nombre }) => setSelectFormat(id, Nombre),
        getIdsKey: "prodsIds"
    }
};

const idsTypeToInfoType = {
    subsIds: 'subfraccionamientos',
    vivsIds: 'viviendas',
    prodsIds: 'productos',
    foliosIds: 'folios'
}

const setInitInfos = () => {
    return {
        subfraccionamientos: { list: [], selectFormat: [] },
        viviendas: { list: [], selectFormat: [] },
        productos: { list: [], selectFormat: [] },
        folios: { selectFormat: [] },
        informes: []
    };
};

const setInitLoadingInfos = () => {
    return {
        subfraccionamientos: false,
        viviendas: false,
        productos: false,
        informes: false
    };
};

const setHeaderFormat = (id, header, disabled = false) => {
    return { id, header, disabled };
};

const setHeaderInfosDefault = () => [
    setHeaderFormat(0, "Producto 1"),
    setHeaderFormat(1, "Producto 2"),
    setHeaderFormat(2, "Producto 3"),
];

const setHeadersInfo = (headerToIds) => Object.entries(headerToIds).map(([key, id]) => setHeaderFormat(id, key));

const setInitConcentradoInfos = (headerToIds) => {
    return {
        newInformes: [],
        headersInfo: setHeadersInfo(headerToIds),
        existingIds: {
            subfraccionamientos: [],
            viviendas: [],
            productos: [],
        },
    }
};

const setInitDetalladoInfos = () => {
    return {
        newInformes: [],
        existingIds: {
            subfraccionamientos: [],
            viviendas: [],
            productos: [],
        },
        foliosFormat: []
    };
};

const setInitFilters = () => {

    const todayDate = new Date();

    const cargosFechasDesdeInit = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
    const cargosFechasHastaInit = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);
    const pagosFechasDesdeInit = new Date(cargosFechasDesdeInit);
    const pagosFechasHastaInit = new Date(cargosFechasHastaInit);

    return {
        subsIds: [],
        vivsIds: [],
        prodsIds: [],
        foliosIds: [],
        pagoEstado: 0,
        desglose: 0,
        cargosFechas: { desde: cargosFechasDesdeInit, hasta: cargosFechasHastaInit },
        pagosFechas: { desde: pagosFechasDesdeInit, hasta: pagosFechasHastaInit },
        importesVacios: 0,
        anclarColumnas: false,
        anclarFilas: true,
        desglosarTotales: true
    };
};

export default function useInformesParameters() {

    const [infos, setInfos] = useState(setInitInfos);
    const [loadingInfos, setLoadingInfos] = useState(setInitLoadingInfos);
    const [filters, dispatch] = useReducer(informesReducer, null, setInitFilters);
    
    const searchFiltersRef = useRef({ desglose: 0, cargosFechas: { desde: null, hasta: null }});

    const loadSubfraccionamientos = useCallback(async (fraccionamiento) => {

        setAnyLoadingInfos('subfraccionamientos', true);

        const response = await getSubfraccionamientos(fraccionamiento);
        const { subfraccionamientos } = response;

        setSelectedInfo('subfraccionamientos', subfraccionamientos);

        setAnyLoadingInfos('subfraccionamientos', false);

        return response;

    }, []);

    const loadViviendas = useCallback(async (fraccionamiento, subfraccionamiento = 0) => {
        
        setAnyLoadingInfos('viviendas', true);

        const response = await getViviendas(fraccionamiento, subfraccionamiento);
        const { viviendas } = response;

        setSelectedInfo('viviendas', viviendas);

        setAnyLoadingInfos('viviendas', false);

        return response;

    }, []);

    const loadProductos = useCallback(async (fraccionamiento) => {

        setAnyLoadingInfos('productos', true);

        const response = await getProductos(fraccionamiento);
        const { productos } = response;

        setSelectedInfo('productos', productos);

        setAnyLoadingInfos('productos', false);

        return response;

    }, []);

    const loadInformes = useCallback(async (id_fraccionamiento) => {

        const { desglose, cargosFechas } = filters;

        if(cargosFechas.desde === null || cargosFechas.hasta === null)
            return { message: "Fecha de busqueda invalida" };

        const searchFiltersCopy = { desglose, cargosFechas };

        setAnyLoadingInfos('informes', true);

        const response = await getInformes({ id_fraccionamiento, ...filters });

        const { informes, error } = response;

        let informesInfo = undefined;

        if (!error) {

            searchFiltersRef.current = searchFiltersCopy;

            informesInfo = searchFiltersCopy.desglose === desgloseTipos.Detallado ?
                    setInformesInfoAsDetallado(informes) : setInformesInfoAsConcentrado(informes)
        }
        else if(searchFiltersCopy.desglose === desgloseTipos.Concentrado) {
            informesInfo = { headersInfo: setHeaderInfosDefault() }
        }

        setAnyLoadingInfos('informes', false);

        return { ...response, ...informesInfo };

    }, [filters]);

    const setSelectedInfo = (type, list) => setInfos(prevInfos => {

        const newInfos = { ...prevInfos };

        const { selectFormat, selected } = getInfoSelectedAndFormatInit(type, list);
        newInfos[type] = { list, selectFormat };

        dispatch({ type: infoListExtractors[type].getIdsKey, value: selected });

        return newInfos;
    });

    const setAnyLoadingInfos = (type, loading) => setLoadingInfos(prevLoadingInfos => {

        const newLoadingInfos = { ...prevLoadingInfos};

        newLoadingInfos[type] = loading;

        return newLoadingInfos;
    });

    const setInformesInfoAsDetallado = (list) => {

        if(list.length === 0) return undefined;

        const detalladoInfo = {
            informes: [],
            selectedIds: { subsIds: [], vivsIds: [], prodsIds: [], foliosIds: [] },
        };

        setInfos(prevInfos => {

            const newInfos = { ...prevInfos };
            const { newInformes, foliosFormat, existingIds } = setDetalladoInfo(list);

            const { subfraccionamientos: subs, viviendas: vivs, productos: prods } = newInfos;
            const { subfraccionamientos: subsIds, viviendas: vivsIds, productos: prodsIds } = existingIds;

            const [subsFormat, subsSelected] =
                getInfoSelectedAndFormat(subs.selectFormat, disableBySameId(subsIds));

            const [vivsFormat, vivsSelected] =
                getInfoSelectedAndFormat(vivs.selectFormat, disableBySameId(vivsIds, setViviendasSelectFormat));

            const [prodsFormat, prodsSelected] =
                getInfoSelectedAndFormat(prods.selectFormat, disableBySameId(prodsIds));

            const foliosSelected = getSelected(foliosFormat);

            newInfos.subfraccionamientos.selectFormat = subsFormat;
            newInfos.viviendas.selectFormat = vivsFormat;
            newInfos.productos.selectFormat = prodsFormat;
            newInfos.folios.selectFormat = foliosFormat;
            newInfos.informes = newInformes;

            detalladoInfo.informes = newInformes;
            detalladoInfo.selectedIds.subsIds = getSelectionValue(subsFormat, subsSelected);
            detalladoInfo.selectedIds.vivsIds = getSelectionValue(vivsFormat, vivsSelected);
            detalladoInfo.selectedIds.prodsIds = getSelectionValue(prodsFormat, prodsSelected);
            detalladoInfo.selectedIds.foliosIds = getSelectionValue(foliosFormat, foliosSelected);

            dispatch({ type: 'allIds', value: [subsSelected, vivsSelected, prodsSelected, foliosSelected] });

            return newInfos;
        });

        return detalladoInfo;
    };

    const setInformesInfoAsConcentrado = (list) => {

        if(list.length === 0) return  { headersInfo: setHeaderInfosDefault() };

        const concentradoInfo = {
            informes: [],
            headersInfo: [],
            selectedIds: { subsIds: [], vivsIds: [], prodsIds: [], foliosIds: undefined }
        };

        setInfos(prevInfos => {

            const newInfos = { ...prevInfos };

            const { newInformes, headersInfo, existingIds } = setConcentradoInfo(list);
            
            const { subfraccionamientos: subs, viviendas: vivs, productos: prods, folios } = newInfos;
            const { subfraccionamientos: subsIds, viviendas: vivsIds, productos: prodsIds } = existingIds;

            const [subsFormat, subsSelected] =
                getInfoSelectedAndFormat(subs.selectFormat, disableBySameId(subsIds));

            const [vivsFormat, vivsSelected] =
                getInfoSelectedAndFormat(vivs.selectFormat, disableBySameId(vivsIds, setViviendasSelectFormat));

            const [prodsFormat, prodsSelected] =
                getInfoSelectedAndFormat(prods.selectFormat, disableBySameId(prodsIds));

            const foliosSelected = getSelected(folios.selectFormat);
            const productosSelectionValue = getSelectionValue(prodsFormat, prodsSelected);

            newInfos.subfraccionamientos.selectFormat = subsFormat;
            newInfos.viviendas.selectFormat = vivsFormat;
            newInfos.productos.selectFormat = prodsFormat;
            newInfos.informes = newInformes;

            concentradoInfo.informes = newInformes;
            concentradoInfo.selectedIds.subsIds = getSelectionValue(subsFormat, subsSelected);
            concentradoInfo.selectedIds.vivsIds = getSelectionValue(vivsFormat, vivsSelected);
            concentradoInfo.selectedIds.prodsIds = productosSelectionValue;

            if(productosSelectionValue) {

                headersInfo.forEach((header) => {
                    header.disabled = productosSelectionValue.findIndex(id_producto => id_producto === header.id) === -1;
                });
            }

            concentradoInfo.headersInfo = headersInfo;

            dispatch({ type: 'allIds', value: [subsSelected, vivsSelected, prodsSelected, foliosSelected] });

            return newInfos;
        });

        return concentradoInfo;

    };

    const setDisabledViviendas = (newSubsIds) => {

        const selected = { subsIds: [], vivsIds: [] };

        setInfos(prevInfos => {

            const newInfos = { ...prevInfos };
            const { viviendas: { selectFormat }, subfraccionamientos: { selectFormat: subsFormat } } = newInfos;

            const [vivsFormat, vivsSelected] =
                getInfoSelectedAndFormat(selectFormat, disableViviendasBySubs(newSubsIds));

            // if(vivsSelected.length === 0) {
            //     const enabledIndeces = getMinEnabled(vivsFormat, 1);

            //     enabledIndeces.forEach((index) => {
            //         vivsFormat[index].checked = true;
            //         vivsSelected.push(vivsFormat[index].id);
            //     });            
            // }

            newInfos.viviendas.selectFormat = vivsFormat;

            selected.vivsIds = getSelectionValue(vivsFormat, vivsSelected);
            selected.subsIds = getSelectionValue(subsFormat, newSubsIds);

            dispatch({ type: 'subsAndVivsIds', value: [newSubsIds, vivsSelected] })

            return newInfos;
        });

        return selected;
    };

    const setDisabledHeaders = (newProdsIds, informes, productosInfo) => {

        const concentradoInfo = {
            headersInfo: [],
            prodsIds: [],
        };

        const { selectFormat } = productosInfo;

        const productosSelectionValue = getSelectionValue(selectFormat, newProdsIds);
        concentradoInfo.prodsIds = productosSelectionValue;

        const headerToIds = getConcentradoProductoIds(informes[0].productos);
        const concentradoHeaders = setHeadersInfo(headerToIds);

        if (productosSelectionValue) {
            concentradoHeaders.forEach((header) => {
                header.disabled = productosSelectionValue.findIndex(id_producto => id_producto === header.id) === -1;
            });
        }

        concentradoInfo.headersInfo = concentradoHeaders;

        dispatch({ type: 'prodsIds', value: newProdsIds });

        return concentradoInfo;
    };

    const setFilters = useCallback((type, value) => {

        dispatch({ type, value });
        return { [type]: getFilterValue(type, value) };
    }, [])

    const setTableSettingsFilter = useCallback((type, value) => {

        dispatch({ type, value });
    }, []);

    const setSelectionFilters = useCallback((type, value, added) => {

        if(type === 'prodsIds' 
            && searchFiltersRef.current.desglose === desgloseTipos.Concentrado 
            && infos.informes.length > 0) 
            return setDisabledHeaders(value, infos.informes, infos.productos);
        
        if(type === 'subsIds') return setDisabledViviendas(value);

        const selected = { [type]: [] };

        const { selectFormat } = infos[idsTypeToInfoType[type]];
        selected[type] = getSelectionValue(selectFormat, value);

        dispatch({ type, value });

        return selected;

    }, [infos]);

    const getExcelFilterValues = useCallback(() => 

        getExcelFilters(infos, filters, searchFiltersRef.current)

    , [infos, filters]);

    return {
        infos,
        loadingInfos,
        filters,
        currentSearchFilters: searchFiltersRef.current,
        loadSubfraccionamientos, loadViviendas, loadProductos, loadInformes,
        setFilters, setSelectionFilters, setTableSettingsFilter, getExcelFilterValues
    };
}

const informesReducer = (state, { type, value }) => {

    switch (type) {
        case 'allIds': return {
            ...state,
            subsIds: value[0],
            vivsIds: value[1],
            prodsIds: value[2],
            foliosIds: value[3]
        }
        case 'subsAndVivsIds': return {
            ...state,
            subsIds: value[0],
            vivsIds: value[1],
        }
        case 'subsIds': return { ...state, subsIds: value }
        case 'vivsIds': return { ...state, vivsIds: value }
        case 'prodsIds': return { ...state, prodsIds: value }
        case 'foliosIds': return { ...state, foliosIds: value }
        case 'pagoEstado': return { ...state, pagoEstado: value }
        case 'desglose': return { ...state, desglose: value }
        case 'cargosFechas': return {
            ...state, cargosFechas: {
                desde: value[0], hasta: value[1]
            }
        }
        case 'pagosFechas': return {
            ...state, pagosFechas: {
                desde: value[0], hasta: value[1]
            }
        }
        case 'vacios': return { ...state, importesVacios: value }
        case 'anclarColumnas': return { ...state, anclarColumnas: value }
        case 'anclarFilas': return { ...state, anclarFilas: value }
        case 'desglosarTotales': return { ...state, desglosarTotales: value }
        default: throw Error("Unknown action: " + type);
    }
};

const getFilterValue = (key, value) => {

    if(key === 'pagoEstado') return value !== 0 ? value : undefined;

    if(key === 'vacios') return value === 0 ? value : undefined;

    if(key === 'pagosFechas') return value[0] !== null && value[1] !== null ? { desde: value[0], hasta: value[1] } : undefined;

    return value;
};

const getSelectionValue = (formatList, selectedList) => 
    formatList.length !== selectedList.length ? selectedList : undefined;

const setDetalladoInfo = (informes) => {

    const detalladoInfo = setInitDetalladoInfos();

    detalladoInfo.newInformes = informes.map((documento) => {
        const { id_subfraccionamiento, id_vivienda, id_producto, id_docCobro: id, num_folio, pagos: pagosJSON, mes_anyo } = documento;

        const subdIdExists = detalladoInfo.existingIds.subfraccionamientos.indexOf(id_subfraccionamiento) !== -1;
        const vivIdExists = detalladoInfo.existingIds.viviendas.indexOf(id_vivienda) !== -1;
        const prodIdExists = detalladoInfo.existingIds.productos.indexOf(id_producto) !== -1;

        if(!subdIdExists) detalladoInfo.existingIds.subfraccionamientos.push(id_subfraccionamiento);
        if(!vivIdExists) detalladoInfo.existingIds.viviendas.push(id_vivienda);
        if(!prodIdExists) detalladoInfo.existingIds.productos.push(id_producto);

        detalladoInfo.foliosFormat.push(setSelectFormat(id, num_folio));

        const pagos = JSON.parse(pagosJSON || "[]");
        pagos.sort((a,b) => a.fecha > b.fecha ? 1 : a.fecha === b.fecha ? 0 : -1);

        const fecha = moment(mes_anyo, "MMYYYY").locale('es').format("MMM YY").replace(".", "");

        return { ...documento, pagos, mes_anyo: fecha };
    });

    return detalladoInfo;
};

const getConcentradoProductoIds = (productos) => productos.reduce((entries, { producto, id_producto }) => {
    return { ...entries, [producto]: id_producto }
}, {});

const setConcentradoInfo = (informes) => {

    const headerToIds = getConcentradoProductoIds(JSON.parse(informes[0].productos));

    const concentradoInfo = setInitConcentradoInfos(headerToIds);

    concentradoInfo.newInformes = informes.map((concentrado) => {

        const newConcentrado = { ...concentrado, productos: JSON.parse(concentrado.productos)}
        const { id_subfraccionamiento, id_vivienda } = newConcentrado;

        newConcentrado.productos.forEach(({ cargo, descuento, recargo, condonacion }, index) => {

            newConcentrado.productos[index].total = cargo + recargo - descuento - condonacion;
        });

        const subdIdExists = concentradoInfo.existingIds.subfraccionamientos.indexOf(id_subfraccionamiento) !== -1;
        const vivIdExists = concentradoInfo.existingIds.viviendas.indexOf(id_vivienda) !== -1;

        if(!subdIdExists) concentradoInfo.existingIds.subfraccionamientos.push(id_subfraccionamiento);
        if(!vivIdExists) concentradoInfo.existingIds.viviendas.push(id_vivienda);

        return newConcentrado;
    });

    concentradoInfo.headersInfo.forEach(({ header: key }) => {
        const id_producto = headerToIds[key];
        concentradoInfo.existingIds.productos.push(id_producto);
    })

    return concentradoInfo;
};

const getExcelFilters = (infos, filters, currentSearch) => {

    const { desglose, cargosFechas } = currentSearch;
    const { subfraccionamientos, viviendas, productos, folios } = infos;
    const { subsIds, vivsIds, prodsIds, foliosIds, pagosFechas, pagoEstado, importesVacios } = filters;

    const getSelectionFiltersValues = (ids, { selectFormat }) => ids.reduce((selected, currentId) => {
        const foundSelected = selectFormat.find(({ id }) => id === currentId);
        if(foundSelected) selected.push(foundSelected.option);
        return selected;    
    }, []).join(", ");

    const getPeriodoLocale = (fecha) => moment(fecha, "MMYYYY").locale('es').format("MMM YY").replace(".", "");
    const getFechaFormat = (fecha) => moment(fecha).format("DD/MM/YYYY");

    const fechaCargosValue = pagosFechas.desde === null || pagosFechas.hasta === null ?
        "Sin rango" : getFechaFormat(pagosFechas.desde) + " - " + getFechaFormat(pagosFechas.hasta);

    return desglose === 0 ?
        {
            'Periodo': getPeriodoLocale(cargosFechas.desde) + " - " + getPeriodoLocale(cargosFechas.hasta),
            'Fecha de cargos': fechaCargosValue,
            'Subfraccionamientos': getSelectionFiltersValues(subsIds, subfraccionamientos),
            'Viviendas': getSelectionFiltersValues(vivsIds, viviendas),
            'Productos': getSelectionFiltersValues(prodsIds, productos),
            'Folios': getSelectionFiltersValues(foliosIds, folios),
            'Estado del pago': pagoEstadosTipos[pagoEstado]
        }
        :
        {
            'Periodo': getPeriodoLocale(cargosFechas.desde) + " - " + getPeriodoLocale(cargosFechas.hasta),
            'Subfraccionamientos': getSelectionFiltersValues(subsIds, subfraccionamientos),
            'Viviendas': getSelectionFiltersValues(vivsIds, viviendas),
            'Productos': getSelectionFiltersValues(prodsIds, productos),
            'Vacios': vaciosTipos[importesVacios]
        };
};

const getInfoSelectedAndFormatInit = (type, list) => list.reduce((collection, item) => {

    const { selectFormat, selected } = collection;
    const format = infoListExtractors[type].getFormat(item);

    selectFormat.push(format);
    selected.push(format.id);

    return collection;

}, { selectFormat: [], selected: [] });


const getInfoSelectedAndFormat = (selectList, disableSelector) => selectList.reduce((collection, item) => {

    const [selectFormat, selected] = collection;
    const format = disableSelector(item);

    selectFormat.push(format);
    if (format.checked) selected.push(format.id);

    return collection;

}, [[], []]);

const disableBySameId = (ids, setFormat = setDefaultSelectFormat) => (format) => {
    const { id, checked } = format;

    const disableIndex = ids.indexOf(id) === -1;
    const newChecked = !disableIndex && checked;

    return setFormat(format, newChecked, disableIndex);
};

const disableViviendasBySubs = (ids) => (format) => {
    const { id_sub, checked } = format;

    const disableIndex = ids.indexOf(id_sub) === -1;
    const newChecked = !disableIndex && checked;

    return setViviendasSelectFormat(format, newChecked, disableIndex)
};
